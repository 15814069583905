<template>
  <div class="">
    <div class="hero bg-grey w-full relative mdmax:px-4 overflow-hidden">
      <div class="shape-3 inline-block absolute top-0 -left-16 mdmax:hidden">
        <img src="@/assets/images/shape3.png" />
      </div>
      <div class="shape-1 inline-block absolute top-0 -right-14 mdmax:hidden">
        <img src="@/assets/images/shape1.png" />
      </div>
      <div class="container mx-auto">
        <div class="flex justify-between mdmax:my-0 mdmax:flex-col">
          <div class="flex-col w-1/2 h-full mdmax:w-full my-24 mdmax:my-0 mdmax:pt-10 mdmax:ml-0 mdmax:mb-6">
            <p
              class="font-extrabold mdmax:font-bold leading-snug text-7xl mdmax:text-4xl mdmax:mb-2 mdmax:w-full text-neutral">
              Indo<span class="text-yellow">Digi</span></p>
            <p class="font-medium mb-3">Kolaborasi <strong>G2Academy</strong> dengan <strong>BINUS Online
                Learning</strong> dan <strong>BCreates</strong></p>
            <p class="text-2xl font-medium mb-3 mdmax:text-base">Dukung Akselerasi Transformasi Digital Dengan Mencetak
              Talenta Digital</p>
            <Button buttonText="Daftar" type="primary" @action="toggleRegistration()" />
          </div>
          <div class="w-1/2 h-full pt-28 mdmax:pt-3 mdmax:w-full flex justify-end">
            <img src="@/assets/images/hero/Indodigi-hero.webp" />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="container">
        <div
          class="py-12 px-24 grid grid-cols-2 gap-12 place-items-center mdmax:px-4 mdmax:grid-cols-none mdmax:grid-rows-2">
          <div>
            <p class="font-bold text-2xl mb-4">Apa itu IndoDigi?</p>
            <p class="text-lg">
              Program yang dirancang sebagai upaya mempercepat proses transformasi digital yang diharapkan dapat berdampak
              bagi bangsa Indonesia dalam membentuk ekosistem digital yang kuat, unggul,
              dan bersaing di masa depan.
            </p>
          </div>
          <img src="@/assets/images/Indodigi-description.webp" class="rounded-md" alt="indodigi" />
        </div>
      </div>
    </div>
    <div class="bg-grey-lighter">
      <div class="container">
        <div class="py-12 grid grid-cols-2 gap-12 mdmax:px-4 mdmax:grid-cols-none mdmax:place-items-start">
            <div class="flex justify-end items-center">
              <img src="@/assets/images/Work-homepage.png" alt="indodigi" />
            </div>
            <div>
                <p class="text-2xl font-bold mb-4">Syarat & Ketentuan</p>
                <ol class="list-decimal pl-6 text-xl text-neutral-500">
                    <li>Lulusan SMA/SMK berprestasi dengan maksimal tahun kelulusan 2020 (dibuktikan dengan nilai rapor)</li>
                    <li>Memiliki Surat Rekomendasi Kepala Sekolah</li>
                    <li>Diutamakan bagi yang belum memiliki pengalaman pekerjaan</li>
                    <li>Memiliki keterampilan dasar di bidang IT (mampu mengoperasikan laptop/PC, menginstal Sistem Operasi, dan familiar dengan aplikasi Ms Office serta dasar pemrograman)</li>
                    <li>Memiliki Surat Persetujuan Orang Tua/Wali</li>
                    <li>Bersedia mengikuti kuliah S1 program kelas karyawan di BINUS Online Learning</li>
                    <li>Bersedia mengikuti <b>Ikatan Dinas selama minimal 4 tahun</b> atau sampai dinyatakan lulus perkuliahan</li>
                    <li>Lolos serangkaian tes seleksi Beasiswa IndoDigi</li>
                  </ol>
            </div>
            </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="container">
        <div class="py-16 mdmax:px-4">
          <p class="text-center font-bold text-3xl mb-8">Mitra Kami</p>
          <div class="grid grid-flow-col justify-center gap-16">
            <img src="@/assets/images/logo-binus-online-new.png" alt="bol" />
           <img src="@/assets/images/bi-creates-new.png" alt="bi-creates" />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-grey-lighter">
      <div class="container">
        <div class="py-16 mdmax:px-4">
          <p class="text-3xl font-bold text-center mb-8">Manfaat Mengikuti Program</p>
          <div class="grid grid-cols-4 gap-16 mdmax:grid-cols-none mdmax:gap-8">
            <div class="">
              <div class="flex justify-center">
                <img src="@/assets/images/indodigi-benefit1.png" alt="indodigi-benefit-1" />
              </div>
              <p class="font-semibold text-lg mb-2 mt-2 text-center">Kuliah Hingga Lulus</p>
              <p class="text-sm text-neutral-500 text-center">Kesempatan berkuliah selama 3,5 tahun dan mendapat gelar S1
                Program Teknik Informatika di BINUS Online Learning.</p>
            </div>
            <div class="">
              <div class="flex justify-center">
                <img src="@/assets/images/indodigi-benefit2.png" alt="indodigi-benefit-1" />
              </div>
              <p class="font-semibold text-lg mb-2 mt-2 text-center">Sertifikat</p>
              <p class="text-sm text-neutral-500 text-center">Raih sertifikat setelah menyelesaikan Bootcamp.</p>
            </div>
            <div class="">
              <div class="flex justify-center">
                <img src="@/assets/images/indodigi-benefit3.png" alt="indodigi-benefit-1" />
              </div>
              <p class="font-semibold text-lg mb-2 mt-2 text-center">Skema Konversi Nilai</p>
              <p class="text-sm text-neutral-500 text-center">Nilai dari Bootcamp dapat dikonversikan menjadi 20 Satuan
                Kredit Semester (SKS).</p>
            </div>
            <div class="">
              <div class="flex justify-center">
                <img src="@/assets/images/indodigi-benefit4.png" alt="indodigi-benefit-1" />
              </div>
              <p class="font-semibold text-lg mb-2 mt-2 text-center">Penempatan Kerja</p>
              <p class="text-sm text-neutral-500 text-center">Kesempatan bekerja di perusahaan partner G2Academy.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="container">
        <div class="py-16 mdmax:px-4">
          <p class="text-center font-bold text-3xl mb-8">Alur Program</p>
          <div class="grid grid-flow-col justify-center">
            <img src="@/assets/images/indodigi-journey.webp" alt="bol" />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-grey-lighter">
      <div class="container">
        <div class="py-16 mdmax:px-4">
          <p class="text-3xl font-bold text-center mb-8">Tahapan Pendaftaran</p>
          <div class="grid grid-cols-4 gap-16 mdmax:grid-cols-none mdmax:gap-8">
            <div class="">
              <div class="flex justify-center">
                <span
                  class="w-28 h-28 rounded-full bg-yellow-100 border border-yellow text-yellow text-4xl flex justify-center items-center">1</span>
              </div>
              <p class="font-semibold text-lg mb-2 mt-6 text-center">Daftarkan diri di website</p>
              <p class="text-sm text-neutral-500 text-center">Masukkan data lengkap di halaman ini.</p>
            </div>
            <div class="">
              <div class="flex justify-center">
                <span
                  class="w-28 h-28 rounded-full bg-yellow-100 border border-yellow text-yellow text-4xl flex justify-center items-center">2</span>
              </div>
              <p class="font-semibold text-lg mb-2 mt-6 text-center">Cek email untuk akun G2Academy</p>
              <p class="text-sm text-neutral-500 text-center">Periksa kotak masuk untuk masuk ke website G2Academy.</p>
            </div>
            <div class="">
              <div class="flex justify-center">
                <span
                  class="w-28 h-28 rounded-full bg-yellow-100 border border-yellow text-yellow text-4xl flex justify-center items-center">3</span>
              </div>
              <p class="font-semibold text-lg mb-2 mt-6 text-center">Upload Dokumen di Website</p>
              <p class="text-sm text-neutral-500 text-center">
                Login ke <router-link to="/user/my-classes" class="text-yellow font-semibold">halaman
                  administrasi</router-link> untuk mengunggah dokumen.
              </p>
            </div>
            <div class="">
              <div class="flex justify-center">
                <span
                  class="w-28 h-28 rounded-full bg-yellow-100 border border-yellow text-yellow text-4xl flex justify-center items-center">4</span>
              </div>
              <p class="font-semibold text-lg mb-2 mt-6 text-center">Admin Kami Akan Menghubungi Kamu</p>
              <p class="text-sm text-neutral-500 text-center">Setelah setiap tahapan dilakukan, admin kami akan
                menghubungi kamu.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="container">
        <div class="py-16 mdmax:px-4">
          <p class="text-center font-bold text-3xl mb-8">Roadshow Sekolah</p>
          <p class="text-center text-2xl font-semibold mb-8 mdmax:text-lg">Kegiatan pengenalan IndoDigi di berbagai
            SMA/SMK</p>
          <div class="flex items-center overflow-hidden">
            <div class="marquee">
              <div v-for="(image, index) in images" :key="index" class="marquee-tag">
                <div class="justify-center">
                  <span>
                    <img :src="require(`@/assets/images/indoDigi/roadshow/${image}`)"
                      :alt="'Value Image ' + (index + 1)" />
                  </span>
                  <p class="font-semibold text-lg mb-2 mt-6 text-center">{{ removeExtentionFile(image) }}</p>
                </div>
              </div>
              <div v-for="(image, index) in images" :key="index" class="marquee-tag">
                <div class="justify-center">
                  <span>
                    <img :src="require(`@/assets/images/indoDigi/roadshow/${image}`)"
                      :alt="'Value Image ' + (index + 1)" />
                  </span>
                  <p class="font-semibold text-lg mb-2 mt-6 text-center">{{ removeExtentionFile(image) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-grey-lighter">
      <div class="container">
        <div class="py-16 mdmax:px-4">
          <p class="text-3xl font-bold text-center mb-8">Berita Terkini</p>
          <p class="text-center text-2xl font-semibold mb-8 mdmax:text-lg">Simak liputan media mengenai IndoDigi</p>
          <div class="grid grid-cols-4 gap-16 mdmax:grid-cols-none mdmax:gap-8">
            <div class="">
              <div class="flex justify-center">
                <a href="https://www.beritasatu.com/ototekno/1033011/g2academy-gandeng-binus-kembangkan-talenta-digital"
                  target="_blank">
                  <img src="@/assets/images/indoDigi/news/Berita Satu.png" alt="Berita Satu" />
                </a>
              </div>
            </div>
            <div class="">
              <div class="flex justify-center">
                <a href="https://m.mediaindonesia.com/humaniora/566525/g2academy-gandeng-binus-luncurkan-program-indodigi"
                  target="_blank">
                  <img src="@/assets/images/indoDigi/news/Media Indonesia.png" alt="Media Indonesia" />
                </a>
              </div>
            </div>
            <div class="">
              <div class="flex justify-center">
                <a href="https://edukasi.sindonews.com/read/1048071/213/gabung-di-beasiswa-indodigi-yuk-ini-syarat-dan-ketentuannya-1678928607"
                  target="_blank">
                  <img src="@/assets/images/indoDigi/news/Sindo News.png" alt="Sindo NEws" />
                </a>
              </div>
            </div>
            <div class="">
              <div class="flex justify-center">
                <a href="https://m.tribunnews.com/amp/techno/2023/03/17/cetak-talenta-digital-muda-g2academy-dan-binus-online-learning-gulirkan-program-bootcamp-indodigi"
                  target="_blank">
                  <img src="@/assets/images/indoDigi/news/Tribun News.png" alt="Tribun News" />
                </a>
              </div>
            </div>
          </div>
          <div class="text-center">
            <Button buttonText="Lihat Selengkapnya" type="secondary" @action="allNews()" />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="container">
        <div class="py-16 mdmax:px-4">
          <p class="text-center font-bold text-5xl mb-5 mdmax:text-2xl">Siap untuk Memulai Dunia Digitalmu?</p>
          <p class="text-center text-2xl font-semibold mb-8 mdmax:text-lg">Lengkapi formulir untuk melanjutkan pendaftaran
          </p>
          <div class="text-center">
            <Button buttonText="Daftar Sekarang" type="primary" @action="toggleRegistration()" />
          </div>
        </div>
      </div>
    </div>
    <Modal :modalVisible="visibleRegistrationModal" @close="toggleRegistration()" id="regis-modal" width="600px">
      <template slot="modal-content">
        <p class="text-3xl font-semibold mb-10">Daftar ke IndoDigi Program</p>
        <div class="form px-10 mdmax:px-0">
          <div class="mb-5">
            <TextField borderEnabled label="Nama Lengkap" asterix v-model="formPayload.name"
              placeholder="Masukkan nama lengkap" />
          </div>
          <div class="mb-5">
            <TextField borderEnabled label="Email" :error="emailError" asterix v-model="formPayload.email"
              placeholder="Masukkan email" />
          </div>
          <div class="mb-5">
            <TextField type="number" borderEnabled label="Nomor Telepon" asterix v-model="formPayload.phoneNumber"
              placeholder="Masukkan nomor telepon" />
          </div>
          <div class="mb-5">
            <TextField borderEnabled label="Nomor Identitas" asterix v-model="formPayload.identityNumber"
              placeholder="Masukkan nomor identitas" />
          </div>
          <div class="mb-5">
            <TextField borderEnabled label="Nama Sekolah" asterix v-model="formPayload.universityName"
              placeholder="Masukkan nama sekolah" />
          </div>
          <div class="mb-10">
            <TextField borderEnabled label="Penjurusan" asterix v-model="formPayload.majorName"
              placeholder="Masukkan penjurusan" />
          </div>
          <Button buttonText="Daftar" type="primary" @action="submit()" :disabled="!readyToSubmit" />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { isNotEmptyError, showVueToast } from '@/utils'
export default {
  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "button" */ '@/components/forms/TextField'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal')
  },
  data() {
    return {
      visibleRegistrationModal: false,
      customerId: 1,
      programCode: 'IndoDigi',
      formPayload: {
        name: '',
        email: '',
        phoneNumber: '',
        identityNumber: '',
        majorName: '',
        universityName: ''
      },
      emailError: '',
      images: []
    }
  },
  computed: {
    readyToSubmit() {
      const error = isNotEmptyError(this.formPayload)
      return error.length === 0
    }
  },
  mounted() {
    this.loadImages()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('scholarship', ['postScholarship']),
    toggleRegistration() {
      this.visibleRegistrationModal = !this.visibleRegistrationModal
    },
    getOnlyText(str) {
      var tmp = document.createElement('DIV')
      tmp.innerHTML = str
      return tmp.textContent.split(' ').join('') || tmp.innerText.split(' ').join('') || ''
    },
    submit() {
      // eslint-disable-next-line
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (!emailPattern.test(this.formPayload.email)) {
        this.emailError = 'Incorrect email format!'
      } else {
        const payload = {
          name: this.formPayload.name,
          email: this.getOnlyText(this.formPayload.email),
          customerId: 1,
          phoneNumber: this.formPayload.phoneNumber,
          programCode: this.programCode,
          additionalInfo: {
            identityNumber: this.formPayload.identityNumber,
            majorName: this.formPayload.majorName,
            universityName: this.formPayload.universityName,
            facultyName: this.formPayload.universityName,
            gpa: 0
          }
        }
        this.showLoading()
        this.postScholarship({
          payload
        })
          .then(() => {
            this.hideLoading()
            this.toggleRegistration()
            this.$router.push('/thankyou-for-scholarship')
          })
          .catch((e) => {
            showVueToast('Email already have scholarship submission', 'error', 2000)
            this.hideLoading()
          })
      }
    },
    loadImages() {
      const imagesContext = require.context('@/assets/images/indoDigi/roadshow', false, /\.(png|jpe?g|jpg|svg)$/)
      this.images = imagesContext.keys().map(key => {
        const fileName = key.split('/').pop()
        return fileName
      })
    },
    allNews() {
      window.open('https://insights.g2academy.co/category/IndoDigi/', '_blank')
    },
    downloadTemplate() {
      window.open('https://g2labcms.s3.ap-southeast-1.amazonaws.com/Template+Surat+Rekomendasi+IndoDigi.docx', '_self')
    },
    removeExtentionFile(filename) {
      var lastIndex = filename.lastIndexOf('.')
      if (lastIndex !== -1) {
        return filename.substring(0, lastIndex)
      } else {
        return filename
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.marquee {
  display: grid;
  grid-auto-flow: column;
  gap: 80px;
  animation: marquee 30s linear infinite running;

  @media screen and (max-width: 767px) {
    gap: 16px;
  }

  &:hover {
    // animation-play-state: paused;
  }

  &-tag {
    width: 400px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 767px) {
      width: calc(100vw - 32px);
    }
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}
</style>
